.App {
  position: relative;
  text-align: center;
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  height: 100vh;
  width: 100vw;
}

#root {
  width: 100%;
}

.overlay {
  background: linear-gradient(90deg, rgba(31,31,61,1) 30%, rgba(31,31,61,0.66) 75%, rgba(31,31,61,0.33) 91%, rgba(0,0,0,0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
}

body.light .overlay {
  background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.66) 75%, rgba(255,255,255,0.33) 91%, rgba(0,0,0,0) 100%);
}

input {
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: 1px solid #343454;
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  height: 80px;
  width: 80%;
  text-align: right;
  padding-right: 20px;
  width: 100%;
}

body.light input {
  color: #1F1F3D;
}

textarea:focus, input:focus{
    outline: none;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 4px;
  z-index: 99;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

body {
  background-color: #1F1F3D;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  transition: 0.2s background-color linear;
}

body.light {
  background-color: white;
}

.text-container {
  color: #A2A2C3;
  opacity: 1;
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  z-index: 99;  
}

body.light .text-container {
  color: #1F1F3D;
}

.text-container.invisible {
  max-height: 0;
  opacity: 0;
  margin-bottom: 0;
  animation: fadeText 1s;
  pointer-events: none;
}

.mode-toggle {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  max-width: 30px;
}

.dark .mode-toggle g {
  fill: white;
}

.light .mode-toggle g {
  fill: #1F1F3D;
}

.dark .mode-toggle .light {
  display: none;
}

.mode-toggle svg, .help-toggle svg {
  height: 100%;
  width: 100%;
}

.help-toggle {
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 80px;
  max-height: 30px;
  max-width: 26px;
}

.dark .help-toggle path {
  fill: white;
}

.light .help-toggle path {
  fill: #1F1F3D;
}

.app-container {
  max-height: 500px;
  position: relative;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .App {
    height: 80vh;
  }

  .overlay {
    width: 40%;
  }

  .app-container {
    position: absolute;
    top: 100px;
    text-align: center;
    left: 0;
    right: 0;
  } 

  p {
    margin-bottom: 8px;
  }   
}

.dark .rodal-dialog {
  border: 1px solid #343454;
  background-color: #1F1F3D;
}

.light .rodal-dialog {
  border: 1px solid #343454;
  background-color: white;
  color: #1F1F3D;
}

.rodal-dialog {
  display: flex;
  align-items: center;
}

.rodal-dialog p {
  margin-bottom: 20px;
}


@keyframes fadeText {
  0% {
    opacity: 1;
    margin-bottom: 30px;
    max-height: 150px;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    margin-bottom: 0;
    max-height: 0;
  }
}

